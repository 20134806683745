
import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';

import {Form, Button, Container, Row, Col} from 'react-bootstrap'
import { login } from '../redux/slices/appSlice'
import { useHistory } from "react-router-dom";





export const Admin = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")

    const App = useSelector(state => state.App)

    const doLogin = (e) => {
        e.preventDefault()
        console.log("doLogin")
        dispatch(login({
            email : Email,
            password : Password
        }))
    }

    useEffect(() => {
        if(App.user.token){
            history.push("/messages")
        }
        return () => {
            
        }
    }, [App.user.token])
    
    
    return (
        <Container>
            <Row style={{
                display : 'flex',
                justifyContent : "center",
                flexDirection : 'column',
                paddingTop : 10
            }}>
                <Col xs={{
                    span : 6, offset: 3
                }} >
                <img src="/images/undraw_secure_login_pdn4.svg" width="200"/>
            <Form style={{
                
                flexDirection : 'column'
            }}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label style={{
                        fontSize : 30,
                        fontWeight : "200"
                    }}>Email</Form.Label>
                    <Form.Control style={{
                        textAlign : 'center',
                        
                    }} value={Email} type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Enter email" />
                    <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label style={{
                        fontSize : 30,
                        fontWeight : "200"
                    }}>Password</Form.Label>
                    <Form.Control style={{
                        textAlign : 'center',
                        
                    }} value={Password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                </Form.Group>
               
                <Button variant="primary" type="submit" onClick={doLogin}>
                    Submit
                </Button>
            </Form>
            </Col>
            </Row>
        </Container>
    )
}

export default Admin
