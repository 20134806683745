// @flow
import fetch from 'isomorphic-fetch'
import {
  DOMAIN
} from '../constants.js'

export const FETCH_REQUESTS_COUNT = "FETCH_REQUESTS_COUNT"
export const RECEIVED_REQUESTS_COUNT = "RECEIVED_REQUESTS_COUNT"

export const FETCH_USERS_COUNT = "FETCH_USERS_COUNT"
export const RECEIVED_USERS_COUNT = "RECEIVED_USERS_COUNT"

export const FETCH_MESSAGES_COUNT = "FETCH_MESSAGES_COUNT"
export const RECEIVED_MESSAGES_COUNT = "RECEIVED_MESSAGES_COUNT";

export const FETCH_PLACES_DATA = "FETCH_PLACES_DATA";
export const RECEIVED_PLACES_DATA = "RECEIVED_PLACES_DATA";


function _fetchUsersCount() {
  return {
    type: FETCH_USERS_COUNT,
    fetchedAt: Date.now()
  }
};

function _receiveUsersCount(json) {
  console.log("receiveUsersCount", json)
  return {
    type: RECEIVED_USERS_COUNT,
    count : json.count,
    receivedAt: Date.now()
  }
};


function _fetchMessagesCount() {
  return {
    type: FETCH_MESSAGES_COUNT,
    fetchedAt: Date.now()
  }
};

function _receiveMessagesCount(json) {
  console.log("receiveMessagesCount", json.count)
  return {
    type: RECEIVED_MESSAGES_COUNT,
    count : json.count,
    receivedAt: Date.now()
  }
};


function _fetchRequestsCount() {
  return {
    type: FETCH_REQUESTS_COUNT,
    fetchedAt: Date.now()
  }
};

function _receiveRequestsCount(json) {
  return {
    type: RECEIVED_REQUESTS_COUNT,
    count : json.count,
    receivedAt: Date.now()
  }
};


function _fetchPlacesCount() {
  return {
    type: FETCH_PLACES_DATA,
    fetchedAt: Date.now()
  }
};

function _receivePlacesData(json) {
  return {
    type: RECEIVED_PLACES_DATA,
    places : json,
    receivedAt: Date.now()
  }
};


export const fetchUsersCount = () => {
  return dispatch => {
    dispatch(_fetchUsersCount())
    return fetch(DOMAIN + "/users/count")
      .then(response => response.json())
      .then(json => dispatch(_receiveUsersCount(json)))
  }
}

export const fetchMessagesCount = () => {
  return dispatch => {
    dispatch(_fetchMessagesCount())
    return fetch(DOMAIN + "/messages/count")
      .then(response => response.json())
      .then(json => dispatch(_receiveMessagesCount(json)))
  }
}

export const fetchRequestsCount = () => {
  return dispatch => {
    dispatch(_fetchRequestsCount())
    return fetch(DOMAIN + "/requests/count")
      .then(response => response.json())
      .then(json => dispatch(_receiveRequestsCount(json)))
  }
}


export const fetchPlacesData = () => {
  return dispatch => {
    dispatch(_fetchPlacesCount())
    return fetch(DOMAIN + "/users/places")
      .then(response => response.json())
      .then(json => dispatch(_receivePlacesData(json)))
  }
}
