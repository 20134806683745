import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {}

const defaultProps = {}

const SideImage = (props) => {
  let {matches} = props
  return(<div style={{
    width : matches.desktop ? "50%" : "100vw",
    height : matches.desktop ? "100vh" : "50vh",
    backgroundColor :"#000",
    overflow : 'hidden',
    float : 'left'
  }}>
      <img style={{
        right : 0,
        marginTop : matches.medium || matches.large ? 0 : 0,
        height : matches.medium || matches.large ?  "100%" : "auto",
        width : matches.medium || matches.large ?  "auto" : "100%",
        float : 'right'
      }} src="/images/Black-gradient.jpg" />

  </div>)
};

SideImage.propTypes = propTypes
SideImage.defaultProps = defaultProps

export default SideImage;
