// @flow
import React from 'react';
import { Provider } from 'react-redux'
import logo from './logo.svg';
import './App.css';
import Home from './pages/Home'
import Admin from './pages/Admin'
import Messages from './pages/Messages'

import configureStore from './redux/configureStore'

import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const store = configureStore({})

function App() {
  return (
    <Provider store={store}>
       <Router>
        <div className="App">
          <Switch>
             
             <Route path="/admin">
               <Admin />
             </Route>
             <Route path="/messages">
               <Messages />
             </Route>
             <Route path="/">
               <Home />
             </Route>
           </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
