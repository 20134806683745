import React, {useState, useMemo, useEffect} from 'react'

import {ChatController, MuiChat} from 'chat-ui-react'
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getMessageDetails, getMessages, sendMessage } from '../redux/slices/appSlice';
import dayjs from 'dayjs'
import io from 'socket.io-client'
import {api} from '../redux/slices/appSlice'
let socket = null
export const Messages = () => {
    const [chatCtl] = useState(new ChatController());
    const App = useSelector(state => state.App)
    const history = useHistory();
    const dispatch = useDispatch()
    

    useEffect(() => {
      // Chat content is displayed using ChatController
       socket = io("https://socket.miasesorlegal.app");
    }, []);
  
    useEffect(() => {
        console.log("App.user", App.user)
        if(!App.user.token)
        {
            history.replace("/admin")
        }
        return () => {
            
        }
    }, [App.user.token])

    useEffect(() => {
        console.log("getMessages")
        dispatch(getMessages({
            id : App.user._id
        }))
        return () => {
            
        }
    }, [])

    useEffect(() => {
        async function addData(){
            if(App.messageDetails){
                
                // Chat content is displayed using ChatController
                App.messageDetails.messages.forEach((msg) => {
                    chatCtl.addMessage({
                        type: 'text',
                        content: msg.content,
                        self: msg.user.lawyer
                    });
                })
               
                chatCtl.setActionRequest(
                    { type: 'text', always: true },
                    (response) => {
                      console.log(response.value);

                      dispatch(sendMessage({
                        userId : App.user._id,
                        id : App.messageDetails._id,
                        message : response.value
                    }))
                      
                    }
                  );
           }
        }

        addData();
        
        return () => {
            
        }
    }, [App.messageDetails])

    const onChatSelected = (message) => {
        console.log("message", message)
        chatCtl.clearMessages();
        if(App.messageDetails._id){
            socket.off("message/", App.messageDetails._id)
        }
        socket.on("message/" + message._id, (data) => {
            console.log("got new message", data)
            if(App.user._id != data.lastMessage.from)
            chatCtl.addMessage({
                type: 'text',
                content: data.lastMessage.message,
                self: false
            });
        })
        dispatch(getMessageDetails({
            userId : message.user._id,
            id : message._id,
            message
        }))
        
    }

    const renderMessage = ((message, key) => {

        return <div key={key} style={{
            width : "100%",
            height : 100,
            borderWidth : 1,
            padding : 10,
            flex : '0 0 auto',
            border : "1px solid black",
            display : 'flex',
            position : 'relative',
            flexDirection : 'column',
            backgroundColor : message.lastMessage && message.lastMessage.read ? "#161628" : "transparent"
        }}
        onClick={() => onChatSelected(message)}
        >

            {message.user && <h6 style={{
                color : message.lastMessage && message.lastMessage.read ? "#ffffff" : "#333333"
            }}>{message.user.firstName} {message.user.lastName}</h6>}
            <p className="truncate" style={{

                height : "100%",
                width : "100%",
                fontSize : 12,
                color : message.lastMessage && message.lastMessage.read ? "#ffffff" : "#333333"
            }}>{message.lastMessage.message && message.lastMessage.message.length > 1 ? message.lastMessage.message : message.content}</p>
            <span style={{
                position : 'absolute',
                bottom : 5,
                fontSize : 10,
                right : 10,
                fontWeight : "700",
                color : message.lastMessage && message.lastMessage.read ? "#ffffff" : "#333333",
                backgroundColor : 'transparent'
            }}>{dayjs(message.updatedAt).format("DD/MMM/YYYY HH:mm a")}</span>

        </div>
    })

    // Only one component used for display
    return <div style={{
        display : 'flex',
        flex : 1,
        height : "100vh",
        width : "100%",
        
    }}>
        <div style={{
            width : 300,
            // height : "100%",
            overflowY : 'scroll',
            display : 'flex',
            flexDirection : 'column',
            
        }}>
            {App.messages.map(renderMessage)}

        </div>
        <div style={{
            flex : 1,
            display : 'flex',
            width : "100%",
            flexDirection : 'column'
        }}>
            <h6 style={{
                padding : 5,
                backgroundColor : "pink"
            }}>{App.messageDetails.content}</h6>
            <MuiChat chatController={chatCtl} />
        </div>
        <style>{`
            .truncate {
                
                
                overflow: hidden;
                text-overflow: ellipsis;
              }
        `}</style>
    </div>
}

export default Messages