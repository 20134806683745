'use strict'

import React from 'react';
import GoogleMapReact from 'google-map-react';

import mapStyles from './mapStyle';

const HEAT_COLOR = "#A93226"
class HeatMap extends React.Component {
  static propTypes = {};
  static defaultProps = {
    center: { //21.329332, -101.989738
      lat: 21.329,
      lng: -101.989
    },
    zoom: 1
  };

  createMapOptions = (maps) => {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style: maps.ZoomControlStyle.SMALL
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT
    },
    mapTypeControl: false,
    styles: mapStyles
  };
}

handlePlacesData = () => {

}

  handleApiLoaded = (map, maps) => {
    // use map and maps objects
    let countryHash = {}
    let {places} = this.props
    // console.log("handleApiLoaded", places)
    places.forEach((placeData) => {

      let place = Object.assign({}, placeData.geoPlace)
      let shortName = place.address_components.pop().short_name
      countryHash[shortName] || (countryHash[shortName] = 0)
      countryHash[shortName]++
      // console.log("shortName", shortName)


    })
    map.data.loadGeoJson('/custom.geo-2.json');
    map.data.setStyle(function(feature) {

               let color = 'transparent';
               let fillOpacity = 0.0
               let wb_a2 = feature.getProperty('wb_a2')
               //console.log("wb_a2", wb_a2)
               //console.log("countryHash[wb_a2]", countryHash[wb_a2])
               if (countryHash[wb_a2]) {
                   color = HEAT_COLOR;

                   fillOpacity = countryHash[wb_a2]/places.length
                   // console.log("fillOpacity", fillOpacity)
               }
               return /** @type {google.maps.Data.StyleOptions} */({
                   fillColor: color,
                   strokeColor: color,
                   strokeWeight: 2,
                   strokeOpacity : fillOpacity,
                   fillOpacity : fillOpacity
               });
           });
  }

  render() {
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBrojD8_Cr8kdjmBN00D0VdaGc7J7vcpE8" }}
        defaultCenter={this.props.center}
        defaultZoom={this.props.zoom}
        onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
        options={this.createMapOptions}
      >

      </GoogleMapReact>
    );
  }
}

export default HeatMap;
