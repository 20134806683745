// @flow
import {
  RECEIVED_MESSAGES_COUNT,
  RECEIVED_USERS_COUNT,
  RECEIVED_REQUESTS_COUNT,
  RECEIVED_PLACES_DATA
} from '../actions/data.action'


export const getMessagesCount = (state = 0, action) => {
  switch (action.type) {
  case RECEIVED_MESSAGES_COUNT:
    return action.count
  default:
    return state
  }
}

export const getRequestsCount = (state = 0, action) => {
  switch (action.type) {
  case RECEIVED_REQUESTS_COUNT:
    return  action.count
  default:
    return state
  }
}

export const getUsersCount = (state = 0, action) => {
  switch (action.type) {
  case RECEIVED_USERS_COUNT:
    return action.count
  default:
    return state
  }
}

export const getPlaces = (state = 0, action) => {
  switch (action.type) {
  case RECEIVED_REQUESTS_COUNT:
    return  action.count
  default:
    return state
  }
}

export const getPlacesData = (state = [], action) => {
  switch (action.type) {
  case RECEIVED_PLACES_DATA:
    return  action.places
  default:
    return state
  }
}
