import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit';
export const TOKEN_PREF = "tokenPref"
export const USER_ID_PREF = "userIDPref"

export const api = Axios.create({
    baseURL : 'https://api.miasesorlegal.app'
})


export const login = createAsyncThunk(
	'app/login',
	async (params) => {
		console.log("login params", params);
		const response = await api.post(
			'/user/login',
			params
		);
		console.log('app/login response', response.data);
		return response.data;
	}
);
export const getMessages = createAsyncThunk(
	'app/getMessages',
	async (params) => {
		console.log("getMessages params", params);
		const response = await api.get(
			'/user/' + params.id + "/request"
		);
		console.log('app/getMessages response', response.data);
		return response.data;
	}
);
export const getMessageDetails = createAsyncThunk(
	'app/getMessageDetails',
	async (params) => {
		console.log("getMessageDetails params", params);
		const response = await api.get(
			'/user/' + params.userId + "/request/" + params.id + "/message"
		);
		console.log('app/getMessageDetails response', response.data);
		response.data = Object.assign({}, response.data, params.message)
		response.data.messages = response.data.messages.reverse()
		return response.data;
	}
);
export const sendMessage = createAsyncThunk(
	'app/sendMessage',
	async (params) => {
		console.log("sendMessage params", params);
		const response = await api.post(
			'/user/' + params.userId + "/request/" + params.id + "/message",
			{
				content : params.message
			}
		);
		console.log('app/sendMessage response', response.data);
		return response.data;
	}
);
export const me = createAsyncThunk(
	'app/me',
	async (params) => {
		console.log("login params", params);
		const response = await api.post(
			'/user/' + params.userId,
			params
		);
		console.log('app/login response', response.data);
		return response.data;
	}
);

const token = localStorage.getItem(TOKEN_PREF)
const userId = localStorage.getItem(USER_ID_PREF)

const setToken = (token) => {
	api.defaults.headers || (api.defaults.headers = {})
	api.defaults.headers["user-token"] = token;
	
}

if(token){
	setToken(token)
}

const appSlice = createSlice({
	name: 'App',
	initialState: {
		token,
		user : {
			token : token ? token : null,
			_id : userId ? userId : null
		},
		messages : [],
		messageDetails : {
			messages : []
		}
	},
	reducers: {
		
	},
	extraReducers: (builder) => {
		//Fecth device info
		builder.addCase(login.pending, (state) => {
			state.status = 'loading';
		});
		builder.addCase(login.fulfilled, (state, action) => {
			state.status = 'succeeded';
			// Add any fetched posts to the array
			localStorage.setItem(TOKEN_PREF, action.payload.token)
			localStorage.setItem(USER_ID_PREF, action.payload._id)
			setToken(action.payload.token)
			state.user = action.payload;
		});
		builder.addCase(login.rejected, (state, action) => {
			state.status = 'failed';
			if (state.error) {
				console.log("login error", JSON.stringify(state.error))
				//console.log("login error.message", JSON.stringify(state.error.message))
			}

			state.loginError = action.error ? action.error.message : '';
		});
		//Get Messages
		builder.addCase(getMessages.pending, (state) => {
			state.status = 'loading';
		});
		builder.addCase(getMessages.fulfilled, (state, action) => {
			state.status = 'succeeded';
			
			state.messages = action.payload;
		});
		builder.addCase(getMessages.rejected, (state, action) => {
			state.status = 'failed';
			if (action.error) {
				console.log("getMessages error", JSON.stringify(action.error.message))
				if(action.error.message.indexOf("401") >= 0){
					localStorage.setItem(TOKEN_PREF, "")
					localStorage.setItem(USER_ID_PREF, "")
					setToken(null)
					state.user = {}
				}
				//console.log("login error.message", JSON.stringify(state.error.message))
			}

			state.messagesError = action.error ? action.error.message : '';
		});
		//Get Message Details
		builder.addCase(getMessageDetails.pending, (state) => {
			state.status = 'loading';
		});
		builder.addCase(getMessageDetails.fulfilled, (state, action) => {
			state.status = 'succeeded';
			
			state.messageDetails = {
				
				...action.payload
			};
		});
		builder.addCase(getMessageDetails.rejected, (state, action) => {
			state.status = 'failed';
			if (action.error) {
				console.log("getMessages error", JSON.stringify(action.error))
				//console.log("login error.message", JSON.stringify(state.error.message))
			}

			state.messagesError = action.error ? action.error.message : '';
		});
		//Send Message
		builder.addCase(sendMessage.pending, (state) => {
			state.status = 'loading';
		});
		builder.addCase(sendMessage.fulfilled, (state, action) => {
			state.status = 'succeeded';
			
			//state.messageDetails.messages.unshift(action.payload);
		});
		builder.addCase(sendMessage.rejected, (state, action) => {
			state.status = 'failed';
			if (action.error) {
				console.log("getMessages error", JSON.stringify(action.error))
				//console.log("login error.message", JSON.stringify(state.error.message))
			}

			state.messagesError = action.error ? action.error.message : '';
		});

	},
});


export const { migrateDeviceID, applyDeviceID } = appSlice.actions

export default appSlice;