import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {fetchUsersCount, fetchRequestsCount, fetchMessagesCount, fetchPlacesData} from '../redux/actions/data.action'
import Media from 'react-media';
import bg from '../images/bg1.jpg'

import HeatMap from '../containers/HeatMap'
import SideImage from './SideImage'

export class Home extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  static defaultProps = {};

  constructor(props){
    super(props)
    this.state = {
      requestCount : 0,
      messageCount : 0,
      userCount : 0,

    }

    this.COUNT_UP_TIMEOUT = 5

  }
  componentDidMount(){
    this.props.dispatch(fetchUsersCount())
    this.props.dispatch(fetchRequestsCount())
    this.props.dispatch(fetchMessagesCount())
    this.props.dispatch(fetchPlacesData())
  }

  render() {
    const {getRequestsCount, getUsersCount, getMessagesCount, getPlacesData} = this.props
    return <Media queries={{
          small: "(max-width: 550px)",
          medium: "(min-width: 551px) and (max-width: 1199px)",
          large: "(min-width: 1200px)",
          desktop : "(min-width: 551px)"
        }}>

        {matches => (
        <div style={{
           // display: 'flex'
      }}>



      <SideImage matches={matches} />
      <div style={{
        position : 'relative',
        width : matches.medium || matches.large ? "49%" : "100vw",
        minHeight : matches.medium || matches.large ? "100vh" : "90vh",
        float : 'left'
      }}>
            <a target="_blank" href="https://apps.apple.com/us/app/mi-asesor-legal/id1448227489">
              <img src="/images/download-on-app-store.png" style={{
                width : matches.desktop ? 200 : 150,
                margin : matches.desktop ? 20 : 10,
                zIndex : 10000
              }}/>
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ectasource.legalhelp">
              <img style={{
                margin: matches.desktop ? 20 : 10,
                width : matches.desktop ? 200 : 150,
                zIndex : 10000
              }} src="/images/download-on-play-store.png" />
            </a>
            <p>
            <a style={{
              marginTop : 50,
              fontSize : 20,
              textAlign:"center"
            }} href="mailto:legal@juanjoselopezvelasco.com">Contáctanos</a>
            </p>
              <h1 style={{
                fontSize : 60,
                fontWeight : 500,
                marginBottom : 10
              }}>Mi Asesor Legal</h1>
              <span style={{
                fontSize: 30,
                fontWeight : 700
              }}>Tu Abogado Personal</span>
              <p style={{
                fontSize : 40,
                marginTop : 60,
                fontWeight : 300,
                marginBottom : 10
              }}>Usuarios registrados</p>
              <span style={{
                fontSize : 50
              }}>{(() => {
                if(this.state.userCount < getUsersCount){
                  this.state.userCount++
                  if(this.userTimeout){
                    clearTimeout(this.userTimeout)
                  }
                  this.userTimeout = setTimeout(() => this.setState({
                    userCount : this.state.userCount
                  }),this.COUNT_UP_TIMEOUT)
                }
                return this.state.userCount
              })()}</span>

              <p style={{
                fontSize : 40,
                marginTop : 60,
                fontWeight : 300,
                marginBottom : 30
              }}>Consultas realizadas</p>
              <span style={{
                fontSize : 50
              }}>{(() => {
                // console.log("requestCount", this.state.requestCount)
                // console.log("getRequestsCount", getRequestsCount)
                if(this.state.requestCount < getRequestsCount){
                  this.state.requestCount++
                  if(this.requestTimeout){
                    clearTimeout(this.requestTimeout)
                  }
                  this.requestTimeout = setTimeout(() => this.setState({
                    requestCount : this.state.requestCount
                  }),this.COUNT_UP_TIMEOUT)
                }
                return this.state.requestCount
              })()}</span>

              <p style={{
                fontSize : 40,
                marginTop : 60,
                fontWeight : 300,
                marginBottom : 30
              }}>Mensajes intercambiados</p>
              <span style={{
                fontSize : 50
              }}>{(() => {

                // console.log("messageCount", this.state.messageCount)
                // console.log("getMessagesCount", getMessagesCount)

                if(this.state.messageCount < getMessagesCount){
                  this.state.messageCount++
                  if(this.messageTimeout){
                    clearTimeout(this.messageTimeout)
                  }
                  this.messageTimeout = setTimeout(() => this.setState({
                    messageCount : this.state.messageCount
                  }),this.COUNT_UP_TIMEOUT)
                }
                return this.state.messageCount
              })()}</span>


      </div>
      {getPlacesData.length && <div className="justify-content-center" style={{
        height: '100vh',
        width: '100%',
        float : 'left',
        marginTop : matches.desktop ? 0 : 50,
        position:'relative'}}>
        <h1 style={{
          position : 'absolute',
          top : 5,
          fontWeight : "300",
          zIndex : 100000,
          fontSize : matches.desktop ? 50 : 30,
          left : "50%",
          backgrounColor : '#333',
          marginLeft : matches.desktop ? -180 : -125,
        }}>Zonas de Consultas</h1>
        <HeatMap places={getPlacesData} />
      </div>}
    </div> )}
    </Media>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const {getRequestsCount, getUsersCount, getMessagesCount, getPlacesData} = state
  return {
    getRequestsCount,
    getUsersCount,
    getMessagesCount,
    getPlacesData
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
