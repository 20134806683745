import { combineReducers } from 'redux'

import appSlice from '../slices/appSlice'
import {getRequestsCount, getMessagesCount, getUsersCount, getPlacesData} from './data.reducer'

const rootReducer = combineReducers({
  getRequestsCount,
  getMessagesCount,
  getUsersCount,
  getPlacesData,
  App : appSlice.reducer
})

export default rootReducer
